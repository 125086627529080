import { ResetComponent } from './reset/reset.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthService } from '@fc-core';
import { CommonModule } from '@angular/common';
import { FormUtilsModule } from '../core/form-utils/form-utils.module';
import { NgObjectPipesModule } from 'ngx-pipes';
import { RouterModule } from '@angular/router';
import { PasswordStrengthModule } from './confirm/password-strength.component';
import { MatIconModule } from '@angular/material/icon';
import { LoaderButtonModule } from '@fc-shared/ui/loader-button/loader-button.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgObjectPipesModule,
    FormUtilsModule,
    FormsModule,
    RouterModule,
    PasswordStrengthModule,
    MatIconModule,
    LoaderButtonModule,
    MatInputModule,
    MatButtonModule,
    IconComponent,
    IconButtonComponent,
  ],
  providers: [AuthService, AuthGuard],
  declarations: [
    LoginComponent,
    LogoutComponent,
    ConfirmComponent,
    ResetComponent,
  ],
})
export class AuthModule {}
