import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Signal,
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { getCorePageTitle } from '@fc-store/selectors/ core-ui.selectors';
import { TextButtonComponent } from '@fc-shared/ui/buttons/text-button.component';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { TagComponent } from '@fc-shared/ui/tags/tag.component';
import { getPlatform, getVersion } from '@fc-shared/utils/data.utils';
import { StrokedButtonComponent } from '@fc-shared/ui/buttons/stroked-button.component';
import { FilledButtonComponent } from '@fc-shared/ui/buttons/filled-button.component';
import { SidePopupComponent } from '@fc-core/components/side-popup.component';
import { NotificationsModule } from '@fc-core/notifications/notifications.module';
import { MenuButtonComponent } from '@fc-shared/ui/buttons/menu-button.component';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { MatIconButton } from '@angular/material/button';
import { setNavbarState } from '@fc-store/actions';
import { getNavbarState } from '@fc-store/selectors/ui.selector';
import { TonalButtonComponent } from '@fc-shared/ui/buttons/tonal-button.component';
import { HasFeatureModule } from '@fc-shared/directives/has-feature/has-feature.module';
import { GlobalSearchService } from '@fc-shared/modules/global-search/services/global-search.service';
import { AvatarPlaceholderModule } from '@fc-shared/ui/avatar-placeholder/avatar-placeholder.module';
import { getCurrentUser, selectRouterParamData } from '@fc-store/selectors';
import { DialogsService, MessagesService, User } from '@fc-core';
import { PermissionsService } from '@fc-core/services/permissions.service';
import { DeveloperOptionsComponent } from '../../../dialogs/developer-options/developer-options.component';
import { PwaService } from '@fc-core/services/pwa-service';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { MatRipple } from '@angular/material/core';
import { QuickCreationComponent } from '@fc-core/components/header/quick-creation/quick-creation.component';
import { CopyToClipDirective } from '@fc-shared/directives/copy-to-clip.directive';
import { HelpCenterDialogComponent } from '@fc-core/components/header/help-center-dialog/help-center-dialog.component';
import { quickCreationNavStructure } from '@fc-core/components/header/quick-creation/quick-creation-nav.structure';
import { MatTooltip } from '@angular/material/tooltip';
import { InputModule } from '@fc-shared/ui/input/input.module';
import { QuickCreationNav } from '@fc-core/components/header/quick-creation/quick-creation.nav';
import { environment } from '../../../../environments/environment';
import { getFeatures } from '@fc-store/selectors/features.selectors';

@Component({
  selector: 'fc-header',
  standalone: true,
  imports: [
    MatIcon,
    RouterLink,
    TextButtonComponent,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    TagComponent,
    StrokedButtonComponent,
    FilledButtonComponent,
    SidePopupComponent,
    NotificationsModule,
    MenuButtonComponent,
    NgTemplateOutlet,
    IconComponent,
    MatIconButton,
    TonalButtonComponent,
    HasFeatureModule,
    AvatarPlaceholderModule,
    NgIf,
    IconButtonComponent,
    MatRipple,
    CopyToClipDirective,
    InputModule,
    MatTooltip,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  store: Store = inject(Store);
  router = inject(Router);
  globalSearch = inject(GlobalSearchService);
  dialogs: DialogsService = inject(DialogsService);
  permissions: PermissionsService = inject(PermissionsService);
  pwaService: PwaService = inject(PwaService);
  messageService = inject(MessagesService);
  title: Signal<string> = this.store.selectSignal(getCorePageTitle);
  navbarState: Signal<boolean> = this.store.selectSignal(getNavbarState);
  currentUser: Signal<User> = this.store.selectSignal(getCurrentUser);
  features: Signal<string[]> = this.store.selectSignal(getFeatures);

  quickCreationNavs = computed(() => {
    return quickCreationNavStructure.filter((nav) => {
      if (nav?.featureKey) {
        return this.features()?.includes(nav.featureKey);
      } else {
        return true;
      }
    });
  });
  quickCreation: Signal<QuickCreationNav> = computed(() => {
    const routeType = this.store.selectSignal(
      selectRouterParamData('quickActionType'),
    )();
    return this.quickCreationNavs()
      .map((nav) => nav.children)
      .flat()
      .find((nav) => nav.type === routeType);
  });

  logoLink =
    environment.environment === 'local' ? ['/components-overview'] : ['/'];
  platform = getPlatform();
  showNews = false;
  showEvents = false;

  openActivity() {
    this.showNews = false;
    this.showEvents = true;
  }

  openNews() {
    this.showEvents = false;
    this.showNews = true;
  }

  toggleNavbarState(): void {
    this.store.dispatch(setNavbarState({ state: !this.navbarState() }));
  }

  get version(): string {
    return getVersion();
  }

  openSearch(): void {
    this.globalSearch.openSearch();
  }

  openDeveloperOptions() {
    this.dialogs.openFullScreenDialog(DeveloperOptionsComponent);
  }

  openConfirmLogout() {
    this.dialogs.openConfirmDialog({
      title: `Logout?`,
      message: 'Are you sure want to logout?',
      buttonText: 'Logout',
      action: () => this.router.navigate(['/logout']),
      buttonColor: 'accent',
    });
  }

  openQuickCreationMenu() {
    this.dialogs.openMobileDialog(QuickCreationComponent);
  }

  openMobileHelp() {
    this.dialogs.openMobileDialog(HelpCenterDialogComponent);
  }

  close(): void {
    this.store.dispatch(setNavbarState({ state: false }));
  }
}
