import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@fc-core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../store';
import { LoggerService } from '@fc-core';

@Injectable()
export class AuthGuard {
  constructor(
    private auth: AuthService,
    private router: Router,
    private store: Store<fromStore.State>,
    private loggerService: LoggerService,
  ) {}

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }

  canActivate() {
    if (this.auth.loggedIn()) {
      this.loggerService.getLogger('fc').debug('AuthGuard: logged in');
      return true;
    } else {
      this.loggerService.getLogger('fc').debug('AuthGuard: not logged in');
      this.router.navigate(['/login']);
      return false;
    }
  }
}
