<div class="header">
  <h2 mat-dialog-title>Choose a customer</h2>
  <div class="close">
    <fc-icon-button mat-dialog-close>
      <fc-icon icon="icon-clear"></fc-icon>
    </fc-icon-button>
  </div>
</div>
<mat-dialog-content>
  <div class="customer-search">
    <mat-form-field appearance="outline">
      <mat-label>Search</mat-label>
      <input
        fcFocusOnAppear
        type="text"
        autocomplete="off"
        [formControl]="searchForm"
        matInput
        placeholder="Search"
        (keyup.enter)="selectCustomer()"
      />
      <fc-icon matSuffix icon="icon-search" class="m-r-12" />
    </mat-form-field>
  </div>

  <ng-container *ngIf="customers$ | async as customers; else loading">
    <mat-list class="list-group">
      <mat-list-item
        (click)="switchCustomer(customer)"
        *ngFor="let customer of customers"
        [class.active]="isCustomerSelected(customer)"
        class="customer"
      >
        <p class="customer-name">{{ customer.name }}</p>
      </mat-list-item>
    </mat-list>
  </ng-container>

  <ng-template #loading>
    <div class="loader">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</mat-dialog-content>
