import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { LocationType } from '@fc-locations/services/location.service';
import { getLocationTypeList, loadLocationTypes } from '@fc-locations/store';
import { User, VehicleType } from '@fc-core';
import {
  getCompanyList,
  getCurrentUser,
  getVehicleTypes,
  loadCompany,
  loadVehicleTypes,
} from '../../../../../store';
import { Company } from '@fc-store/model/company.model';
import { GlobalSearchService } from '@fc-shared/modules/global-search/services/global-search.service';
import { corePlatformColors } from '@fc-core/constants/colors';
import { SearchCategory } from '@fc-shared/modules/global-search/models/category';

import { SearchResultsResponse } from '@fc-shared/modules/global-search/models/search-results.response';

@Component({
  selector: 'fc-global-search-results',
  templateUrl: './global-search-results.component.html',
  styleUrls: ['./global-search-results.component.scss'],
})
export class GlobalSearchResultsComponent implements OnInit, OnChanges {
  @Input() results: SearchResultsResponse;
  @Input() searchString: string;
  @Input() searchCategory: SearchCategory;
  @Input() searchLoading: boolean;
  @Output() loadMore = new EventEmitter<void>();
  readonly store: Store = inject(Store);
  readonly searchService: GlobalSearchService = inject(GlobalSearchService);
  protected readonly PlatformColors = corePlatformColors;
  locationTypes$: Observable<LocationType[]>;
  vehicleTypeList$: Observable<VehicleType[]>;
  companyList$: Observable<Company[]>;
  currentUser$: Observable<User>;
  hasResults: boolean;
  showEmptyState: boolean;
  trackByFn = (index) => index;

  ngOnInit(): void {
    this.initSearchData();
    this.companyList$ = this.store.select(getCompanyList);
    this.vehicleTypeList$ = this.store.select(getVehicleTypes);
    this.locationTypes$ = this.store.select(getLocationTypeList);
    this.currentUser$ = this.store.select(getCurrentUser);
  }

  ngOnChanges(): void {
    this.setHasResults();
    this.setShowEmptyState();
  }

  private setShowEmptyState(): void {
    const categories = ['vehicle', 'location', 'contact', 'employee'];

    if (this.searchLoading || !this.searchString) {
      this.showEmptyState = false;
    } else if (!this.hasResults) {
      this.showEmptyState = true;
    } else if (categories.includes(this.searchCategory.value)) {
      this.showEmptyState =
        this.results?.[this.searchCategory.value]?.length === 0;
    }
  }

  private setHasResults() {
    this.hasResults =
      this.results?.vehicles?.length > 0 ||
      this.results?.employees?.length > 0 ||
      this.results?.locations?.length > 0 ||
      this.results?.crm?.length > 0;
  }

  initSearchData() {
    if (!this.searchService.dataLoaded) {
      this.store.dispatch(loadCompany(null));
      this.store.dispatch(loadLocationTypes());
      this.store.dispatch(loadVehicleTypes());
    }
    this.searchService.dataLoaded = true;
  }

  showResult(type: string, categoryName: string): boolean {
    return (
      this.results?.[type]?.length > 0 &&
      (this.searchCategory?.value === 'all' ||
        this.searchCategory?.name === categoryName)
    );
  }
}
