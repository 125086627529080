import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'fc-hijack',
  template: ``,
})
export class HijackComponent {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
  ) {
    const userId = this.route.snapshot.params.id;
    this.http
      .post(environment.apiUrl + 'api/hijack/', { user: userId })
      .subscribe((response) => {
        localStorage.setItem('hijackToken', response['token']);
        this.router.navigate(['map']).then(() => location.reload());
      });
  }
}
