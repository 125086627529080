<div class="wrapper">
  <div class="logo"></div>

  <div class="auth-card">
    <ng-container *ngIf="!successText; else showSuccessText">
      <div class="h1 title">Forgot password?</div>
      <div class="auth-subtitle">
        Please enter your registered email below to receive password reset
        instruction
      </div>
      <form class="auth-form" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
        <fc-form-error [formGroup]="resetForm"></fc-form-error>
        <mat-form-field appearance="outline" class="form__field">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            name="email"
            placeholder="Email"
            class="form__input"
            formControlName="email"
          />
          <mat-error>
            <fc-form-error
              [formGroup]="resetForm"
              [path]="['email']"
              [customErrors]="{
                required: 'Email is required',
                pattern: 'Please enter a valid email'
              }"
            ></fc-form-error>
          </mat-error>
        </mat-form-field>
        <fc-loader-button
          (buttonClick)="onSubmit()"
          [options]="{
            buttonColor: 'primary',
            spinnerColor: 'accent',
            raised: true,
            text: 'Request password reset',
            spinnerSize: 20,
            active: loading,
            disabled: resetForm.invalid || loading,
            mode: 'indeterminate'
          }"
        ></fc-loader-button>
      </form>
    </ng-container>

    <ng-template #showSuccessText>
      <img
        class="success-image"
        src="/assets/icons/auth/success-image.svg"
        alt="success-image"
      />
      <div class="h1 title">Check your email</div>
      <div class="auth-subtitle">
        We sent a password reset link to
        <span class="user-email">{{ email }}</span
        >. Click the reset password link to set a new password.
      </div>
      <button
        class="success-button"
        mat-flat-button
        color="primary"
        (click)="successText = null"
      >
        {{ 'Resend email' | uppercase }}
      </button>
    </ng-template>
  </div>
  <div class="form-footer single">
    <a class="form-link" routerLink="/login"> Back to Sign in </a>
  </div>
</div>
