import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './auth/logout/logout.component';
import { LoginComponent } from './auth/login/login.component';
import { InternalAppComponent, PageNotFoundComponent } from '@fc-core';
import { AuthGuard } from './auth/auth-guard.service';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { ResetComponent } from './auth/reset/reset.component';
import { ConfirmComponent } from './auth/confirm/confirm.component';
import { MapResolverService } from './core/services/map-resolver.service';
import { HijackComponent } from './core/hijack.component';

export const routes: Routes = [
  {
    path: 'components-overview',
    loadChildren: () =>
      import('./components-overview/components-overview.module').then(
        (m) => m.ComponentsOverviewModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'invitation/:key',
    loadChildren: () =>
      import('./auth/invitation/invitation.module').then(
        (m) => m.InvitationModule,
      ),
  },
  {
    path: 's/:shareId',
    loadChildren: () =>
      import('./vehicle-sharing/vehicle-sharing.module').then(
        (m) => m.VehicleSharingModule,
      ),
    resolve: {
      map: MapResolverService,
    },
  },
  {
    path: 'hijack/:id',
    component: HijackComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'reset',
    component: ResetComponent,
  },
  {
    path: 'confirm/:token/:uid',
    component: ConfirmComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/dashboard',
  },
  {
    path: '',
    component: InternalAppComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'map',
        loadChildren: () => import('./map/map.module').then((m) => m.MapModule),
        resolve: {
          map: MapResolverService,
        },
        data: {
          title: 'Map',
          quickActionType: 'vehicles',
        },
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: {
          title: 'Dashboard',
          quickActionType: 'vehicles',
        },
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
        data: {
          title: 'Settings',
          quickActionType: 'vehicles',
        },
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./reports/reports.module').then((m) => m.ReportsModule),
        data: {
          title: 'Reports',
          quickActionType: 'vehicles',
        },
      },
      {
        path: 'locations',
        loadChildren: () =>
          import('./locations/locations.module').then((m) => m.LocationsModule),
        resolve: {
          map: MapResolverService,
        },
        data: {
          title: 'Locations',
          quickActionType: 'locations',
        },
      },
      {
        path: 'crm',
        loadChildren: () => import('./crm/crm.module').then((m) => m.CrmModule),
        data: {
          title: 'CRM',
        },
      },
      {
        path: 'vehicles',
        loadChildren: () =>
          import('./vehicles/vehicles.module').then((m) => m.VehiclesModule),
        resolve: {
          map: MapResolverService,
        },
        data: {
          title: 'Vehicles',
        },
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./employees/employees.module').then((m) => m.EmployeesModule),
        data: {
          title: 'Employees',
        },
      },
      {
        path: 'shared',
        loadChildren: () =>
          import('./live-share/live-share.module').then(
            (m) => m.LiveShareModule,
          ),
        data: {
          title: 'Live Share',
        },
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./events/events.module').then((m) => m.EventsModule),
        data: {
          title: 'Events',
          quickActionType: 'events',
        },
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: environment.log.routing,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
