import { Component, inject, input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { Customer, DialogsService } from '@fc-core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CustomerChooserDialogComponent } from '../../../dialogs';
import * as fromAdminStore from '../../../store';
import { currentUserHasLegacyPermission } from '../../../store';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'fc-nav-footer-accordion',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    RouterLink,
    IconComponent,
  ],
  templateUrl: './nav-footer-accordion.component.html',
  styleUrls: ['./nav-footer-accordion.component.scss'],
})
export class NavFooterAccordionComponent {
  isOpen = input<boolean>();

  dialogsService: DialogsService = inject(DialogsService);
  store = inject(Store);

  currentCustomer$: Observable<Customer> = this.store.select(
    fromAdminStore.getCurrentCustomer,
  );
  canSwitchCustomer = this.store.selectSignal(
    currentUserHasLegacyPermission(
      'accounting_customer_can_switch_customer_view',
    ),
  );

  get getEnvironmentName(): string {
    return environment.environment;
  }

  openCustomerChooserDialog() {
    if (this.canSwitchCustomer())
      this.dialogsService.openFullScreenDialog(CustomerChooserDialogComponent);
  }
}
