import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthService, LoggerService, MessagesService, User } from '@fc-core';
import { DRFFormValidation } from '@fc-core/form-utils/drfform-validation';
import { EMAIL_REGEX } from '../custom-validators';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { FirebaseService } from '@fc-core/services/firebase.service';

@Component({
  selector: 'fc-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth-base.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading: boolean;
  showPassword: boolean;

  constructor(
    public auth: AuthService,
    private router: Router,
    private builder: UntypedFormBuilder,
    private loggerService: LoggerService,
    private messagesService: MessagesService,
    private firebaseService: FirebaseService,
  ) {}

  ngOnInit() {
    this.loginForm = this.builder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
      password: ['', [Validators.required]],
    });

    this.loggerService.getLogger('fc').debug('Login Comp');
    if (this.auth.loggedIn()) {
      this.router.navigate(['/map']);
    }
  }

  onSubmit() {
    this.loading = true;
    this.loggerService.getLogger('fc').debug('Logging in');
    this.auth
      .login(
        this.loginForm.get('email').value,
        this.loginForm.get('password').value,
      )
      .pipe(catchError((err) => of(this.loginFailed(err))))
      .subscribe((user: User) => this.tryToLogIn(user));
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  tryToLogIn(user: User): void {
    if (user == null) return;
    this.firebaseService.init();
    this.router.navigate(['/map']);
  }

  loginFailed(err: HttpErrorResponse): HttpErrorResponse {
    this.loading = false;
    DRFFormValidation.handleError(this.loginForm)(err);
    this.messagesService.showMessageFromError(err);
    return err;
  }

  trySubmit() {
    if (this.loginForm.invalid || this.loading) return;
    this.onSubmit();
  }
}
