import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthService, MessagesService } from '@fc-core';
import { DRFFormValidation } from '@fc-core/form-utils/drfform-validation';
import { EMAIL_REGEX } from '../custom-validators';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'fc-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['../auth-base.scss'],
})
export class ResetComponent implements OnInit {
  resetForm: UntypedFormGroup;
  successText: string;
  loading: boolean;

  constructor(
    private authService: AuthService,
    private builder: UntypedFormBuilder,
    private messagesService: MessagesService,
  ) {}

  ngOnInit() {
    this.resetForm = this.builder.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
    });
  }

  get email(): string {
    return this.resetForm.get('email').value;
  }

  onSubmit() {
    this.loading = true;
    this.authService
      .reset(this.resetForm.controls.email.value)
      .pipe(
        map((data) => {
          this.loading = false;
          if (data.detail) {
            this.successText = data.detail;
          }
        }),
        catchError((err) => {
          this.loading = false;
          DRFFormValidation.handleError(this.resetForm)(err);
          this.messagesService.showMessageFromError(err);
          return err;
        }),
      )
      .subscribe();
  }
}
