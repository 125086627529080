<div
  class="footer-accordion-container"
  [class.closed]="!isOpen"
  [class.open]="isOpen()"
>
  <div
    (click)="openCustomerChooserDialog()"
    *ngIf="canSwitchCustomer()"
    [class.closed]="!isOpen()"
    [class.open]="isOpen()"
    class="customer-block"
    matRipple
  >
    <div class="customer-logo">
      <fc-icon icon="icon-business"></fc-icon>
    </div>

    <div class="customer-title">{{ (currentCustomer$ | async)?.name }}</div>
    <div *ngIf="canSwitchCustomer()" class="icon">
      <fc-icon [size]="20" icon="icon-swap"></fc-icon>
    </div>
  </div>

  <div
    *ngIf="getEnvironmentName !== 'production'"
    class="env-badge-container m-t-16"
  >
    {{ getEnvironmentName }}
  </div>
</div>
