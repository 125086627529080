import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  Customer,
  CustomerApiService,
  LoggerService,
} from '@fc-core';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'fc-customer-chooser-dialog',
  templateUrl: './customer-chooser-dialog.component.html',
  styleUrls: ['./customer-chooser-dialog.component.scss'],
})
export class CustomerChooserDialogComponent implements OnInit {
  customers$: Observable<Customer[]>;
  searchForm: UntypedFormControl = new UntypedFormControl('');
  selectedCustomer: Customer;

  constructor(
    private parent: ElementRef,
    private auth: AuthService,
    private customerApi: CustomerApiService,
    private loggerService: LoggerService,
  ) {}

  ngOnInit() {
    this.customers$ = combineLatest([
      this.customerApi.loadAll(),
      this.searchForm.valueChanges.pipe(startWith('')),
      fromEvent(document, 'keyup').pipe(
        filter((e) => e['which'] === 38 || e['which'] === 40),
        startWith(''),
      ),
    ]).pipe(
      map(([customers, search, keyEvent]) => {
        let filteredCustomers;
        if (search.trim().length === 0) filteredCustomers = customers;
        filteredCustomers = customers.filter(
          (customer) =>
            customer.name.toLowerCase().search(search.toLowerCase()) !== -1,
        );

        this.selectCustomerWithArrowKey(filteredCustomers, keyEvent['which']);

        return filteredCustomers;
      }),
    );
  }

  private selectCustomerWithArrowKey(customers: Customer[], keyNumber: number) {
    let currentIndex = this.selectedCustomer
      ? customers.map((i) => i.id).indexOf(this.selectedCustomer.id)
      : 0;
    if (customers.length > 0) {
      if (keyNumber === 38) {
        currentIndex = currentIndex !== 0 ? currentIndex - 1 : 0;
      } else if (keyNumber === 40) {
        if (!this.selectedCustomer) {
          currentIndex = 0;
        } else {
          currentIndex =
            currentIndex !== customers.length ? currentIndex + 1 : currentIndex;
        }
      } else if (keyNumber === 13) {
        this.switchCustomer(this.selectedCustomer);
      }
      this.selectedCustomer = customers[currentIndex];
      this.scrollToSelectedCustomer(currentIndex);
    }
  }

  private scrollToSelectedCustomer(customerIndex: number) {
    const position =
      this.parent.nativeElement.querySelectorAll('.customer')[customerIndex]
        ?.offsetTop;
    this.parent.nativeElement.querySelector('mat-dialog-content').scrollTo({
      top: position - 180,
      left: 100,
      behavior: 'smooth',
    });
  }

  switchCustomer(customer: Customer) {
    this.loggerService.getLogger('fc').debug('Switching to ', customer);
    this.auth.switchToCustomer(customer.id).subscribe();
    return false;
  }

  isCustomerSelected(customer: Customer): boolean {
    if (!this.selectedCustomer) return;
    return customer.id === this.selectedCustomer.id;
  }

  selectCustomer() {
    if (this.selectedCustomer) this.switchCustomer(this.selectedCustomer);
  }
}
