<div
  class="results-value"
  (click)="onItemClick()"
  [class.selected]="isItemSelected"
>
  <div class="result-item">
    <fc-user-avatar
      [user]="result"
      [size]="28"
      [fontSize]="14"
    ></fc-user-avatar>

    <div class="result-desc">
      <div class="result-title" [innerHTML]="name"></div>
      <div class="result-subtitle">
        {{ result.primaryPhoneNumber }}
      </div>
    </div>
  </div>
</div>
