<div class="wrapper">
  <div class="logo"></div>
  <div class="auth-card">
    <div class="h1 title">Sign in</div>
    <div class="auth-subtitle">Welcome back! Please enter your details</div>
    <form class="auth-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          name="email"
          placeholder="Email"
          formControlName="email"
        />
        <mat-error>
          <fc-form-error
            [formGroup]="loginForm"
            [path]="['email']"
            [customErrors]="{
              pattern: 'Email is invalid',
              required: 'Email is required'
            }"
          >
            >
          </fc-form-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          name="password"
          placeholder="Password"
          formControlName="password"
          (keydown.enter)="trySubmit(); $event.preventDefault()"
        />
        <fc-icon-button
          (click)="$event.preventDefault(); togglePassword()"
          matSuffix
        >
          <fc-icon
            [icon]="
              showPassword ? 'icon-visibility_off' : 'icon-visibility'
            "
            class="toggle-password-icon"
          >
          </fc-icon>
        </fc-icon-button>
        <mat-error>
          <fc-form-error
            [formGroup]="loginForm"
            [path]="['password']"
            [customErrors]="{
              required: 'Password is required'
            }"
          ></fc-form-error>
        </mat-error>
      </mat-form-field>
      <fc-loader-button
        (buttonClick)="onSubmit()"
        [options]="{
          buttonColor: 'primary',
          spinnerColor: 'accent',
          raised: true,
          text: 'Sign in',
          spinnerSize: 20,
          active: loading,
          disabled: loginForm.invalid || loading,
          mode: 'indeterminate'
        }"
      ></fc-loader-button>
    </form>
  </div>
  <div class="form-footer">
    <a class="form-link" routerLink="/reset"> Forgot password? </a>
    <a class="form-link" href="mailto:support@fleetchaser.com"> Contact Us </a>
  </div>
</div>
